@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap')

body
  font-family: 'Open Sans', sans-serif

.circle-m
  width: 300px
  height: 300px
  border-radius: 50%
  overflow: hidden
  border: 20px solid #4BD5E7

.center-hz-abs
  position: absolute
  top: 50%
  transform: translateY(-50%)

.r-10
  right: 10%

.first-section
  width: 100%

.videoContainer
  z-index: 1
  overflow: hidden
  min-width: 500px
  min-height: 200px
  /* max-height: 100vh */
  transition: 1.5s all ease-in
  opacity: 0
  video
    /* border: 1rem solid rgb(9, 96, 98) */
    /* border-radius: 1rem */
    /* width: calc(100% - 2rem) */

.videoContainer.videoLoaded
  opacity: 1

.second
  position: relative
  width: 100%
  height: 100vh

.second-title
  font-family: 'Akira'
  margin-bottom: 0.5em
  position: relative
  color: #4BD5E7
  font-size: 2em

.third-title
  font-family: 'Akira'
  margin-bottom: 0.5em
  position: relative
  color: #4BD5E7
  font-size: 3em
  text-align: center

.first-section-grid
  width: 80%
  display: grid
  position: relative
  padding: 120px 0 1em 0
  row-gap: 1rem
  margin: auto
  > div
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    h1
      text-align: center
      font-size: 4em
  p
    transition: 1.5s all cubic-bezier(0.05, 1.79, 0.75, 0.72)

.first-title
  color: #4BD5E7
  margin: 1rem 0
  font-size: 4em
  /* transition: 1.5s all cubic-bezier(0.05, 1.79, 0.75, 0.72) */
  line-height: 1em
  font-family: 'Akira'

.hidden-left
  position: fixed
  left: 0
  transform: translateX(-100%)

.hidden-right
  position: fixed
  left: 0
  transform: translateX(100%)

.f-s-g-v
  p
    position: relative
    left: unset
    transform: none
  .hidden-right
    position: relative
    left: unset
    transform: none

.new-web-content
  position: relative
  padding: 0

.offer
  *
    font-family: 'Akira'