$breakpoint-device-width-min: 1025px;
@media (min-width: $breakpoint-device-width-min) {
    $header-height: 120px;

    body {
        overflow-x: hidden !important;
        overflow-y: auto !important;
    }

    section {
        overflow: none;
        height: unset;
    }

    .web-section {
        position: relative;
        height: 100vh;
    }

    .menu-list {
        display: none;
    }

    .header-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-decoration: none;
        font-size: calc(2vw + 10px);
        font-family: "GalaxyBT";
    }

    .menu-trigger {
        display: none;
    }

    .pp-tableCell {
        padding: 0 !important;
    }

    #main .section-content .section-core {
        background-color: black;
    }

    #main .section-content .section-header {
        display: none;
    }

    #header-web {
        display: grid;
        position: absolute;
        background: transparent;
        top: 0;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: none;
        height: $header-height !important;
        padding: 1em;
        height: unset !important;
    }

    .logo-video {
        display: block;
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 10rem;
        z-index: 1;
    }

    .logo-c {
        display: flex;
        width: unset;
        padding: 3vh 7vw;
        padding: 40px;
    }

    .section-content {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
    }

    .pp-tableCell {
        padding: $header-height 0 0 0 !important;
    }

    .section-header {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .paragraph-content {
        padding: 0 10px;
    }

    .about-us-header {
        order: 2;
    }

    #myVideo {
        position: absolute;
        top: 0;
        height: 100%;
        max-width: unset;
        left: 50% !important;
        transform: translate(-50%, 0) !important;
    }

    .main-legend-c {
        display: grid;
        grid-column: 1/3;
        grid-template-columns: 15vh 1fr 1fr 1fr 15vh;
        grid-template-rows: 15vh 1fr 1fr 1fr 15vh;
    }

    .main-legend-div {
        grid-column: 2/4;
        grid-row: 3/5;
        z-index: 1;
        overflow: visible;
        display: flex;
        align-items: flex-end;
    }

    .main-legend {
        color: white;
        font-family: "GalaxyBT";
        font-size: calc(20px + 3.5vw);
        margin: 0;
        background: rgba(0, 0, 0, 0.4);
        padding: 1rem;
    }

    .main-legend p {
        margin: 0;
        font-size: calc(0.2em + 1vw);
    }

    .video-gradient {
        background: rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .contact-button {
        display: none;
    }

    #pagepiling {
        display: none;
    }

    .masonry-with-columns {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 1000px;
        div {
            flex: 1 0 auto;
            background: #ec985a;
            color: white;
            margin: 0 1rem 1rem 0;
            text-align: center;
            font-family: system-ui;
            font-weight: 900;
            font-size: 2rem;
        }
        @for $i from 1 through 36 {
            div:nth-child(#{$i}) {
                $h: (random(400) + 100) + px;
                height: $h;
                line-height: $h;
            }
        }
    }

    .web-content {
        position: relative;
        padding: 2rem 4rem;
        display: grid;
        row-gap: 2rem;
    }

    footer {
        background: black;
        padding: 2rem 4rem;
        display: flex;
        justify-content: center;
    }

    footer > div {
        padding: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    footer > div > div {
        padding: 2px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
    }

    .icon-c-small {
        width: 26px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fa-home {
        margin: 0;
    }

    .footer-info {
        font-weight: 600;
        font-size: 1em;
        width: 300px;
    }

    .legend {
        padding: 2rem 4rem;
        background: black;
        color: white;
    }

    .legend > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .legend > div > span {
        font-weight: 600;
        font-size: calc(1em + 1vw);
        font-family: "GalaxyBT";
    }

    .offer {
        display: grid;
        grid-template-columns: 0.9fr 0.8fr 1.2fr;
        grid-template-rows: 425px;
        grid-column-gap: 1em;
    }

    .offer > div {
        display: flex;
        flex-direction: column;
    }

    .web-span {
        text-transform: UPPERCASE;
        font-family: "SpaceBD";
        font-size: 2em;
        color: #606062;
    }

    .mobile-span {
        text-transform: UPPERCASE;
        font-family: "SpaceBD";
        font-size: 2em;
        text-align: center;
    }

    .near-span {
        text-transform: uppercase;
        font-family: "SpaceBD";
        color: rgb(1,1,100);
        font-size: 4em;
        letter-spacing: -3px;
    }

    #header {
        display: none;
    }

    div.legend-title {
        font-family: "SpaceBD";
        display: flex;
        justify-content: left;
        padding-bottom: 1rem;
        font-size: calc(1em + 2.5vw);
    }
}
