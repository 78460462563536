.app-development-page
  position: relative
  min-height: 100vh
.img-clip-app-development
  transform: scaleX(-1)
  background-size: contain
  width: 500px
  height: 500px

.app-development-title
  font-family: 'Akira'
  font-size: 3em
  line-height: 0.9
  z-index: 2
  &.outline
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
    color: white

@media (max-width: 880px)
  .app-development-title
    font-size: 1.2em !important
  .app-development-text
    font-size: 1em !important

@media (max-width: 1300px) and (min-width: 881px)
  .app-development-title
    font-size: 2em !important

@media (min-width: 1500px) 
  .app-development-text
    font-size: 2em !important

@media (max-width: 1360px)
  .app-development-page-container
    margin-left: 55px !important

@media (min-width: 1361px)
  .app-development-page-container
    margin-left: 10% !important

@media (max-width: 850px)
  .nearshore-bg
    width: 90% !important

.app-development-page-container
  padding: 200px 0 120px 0
  max-width: 515px
  display: flex
  flex-direction: column

.app-development-text-container
  display: block

.app-development-text
  clear: left
  font-family: Minion
  font-size: 1.2em
  margin-top: 1em
  &:first-child
    margin-top: 2em

.app-development-figure
  shape-outside: polygon(60.6% 3.48%, 76% 8.51%, 77.72% 17.17%, 88.91% 31.33%, 84.03% 67.65%, 64.09% 70.61%, 64.47% 80.95%, 76.87% 81.62%, 82.65% 97.28%, 22.11% 97.34%, 31.03% 88.26%, 27.65% 66.45%, 15.99% 68.18%, 12.3% 60.74%, 35.73% 46.96%, 36.01% 37.63%, 48.60% 31.28%)
  float: right
  transform: translateY(-30px)

.app-development-bg
  position: absolute
  z-index: 0
  top: 0  
  right: 0
  width: 50vw
  height: 100%
  &.on-top
    z-index: 1
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.3) 14%, rgba(255,255,255,0) 100%)

.app-development-content
  z-index: 5

figure
  margin-block-start: 0
  margin-inline-start: 0
  margin-block-end: 0
  margin-inline-end: 0

/* con width 1024px es el minimo para un width de 90% de ancho de container y 60% de contenido */